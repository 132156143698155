import BaseBean from "@/utils/BaseBean";

export interface IBrandCardDataObj {
    utilInst:BrandCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class BrandCardUtil extends BaseBean{
    public dataObj:IBrandCardDataObj;

    constructor(proxy:any,dataObj:IBrandCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();


        // const script = document.createElement('script');
        // script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
        // script.onload = this.initSDK;
        // document.body.appendChild(script);
    }
    public initSDK():any{
        // @ts-ignore
        const obj = new WxLogin({
            self_redirect: false,
            id: "qrcode-container",
            appid: "wx1104aebcf215cb84",
            scope: "snsapi_login", // 网页默认即可
            // 授权成功后回调的url，这个路径要urlEncode转码的，注意，要跳转的地址必须在申请的域名下面。
            redirect_uri: encodeURIComponent("https://sdmsdev.suxiangkj.com"),
            state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
            style: 'black', // 提供"black"、"white"可选。二维码的样式
            href: '' //二维码的外部自定义样式链接url，需要https类型
        });
        console.log(obj)
    }
    public genCode():any{
        let local = window.location.href; //当前路径，注意：路径必须是在公众号配置过的
        let APPID = 'wx1104aebcf215cb84'; //此为公众号的openId
        let code = this.getUrlParam('code') //工具函数，获取code参数信息
        if (code == "" || code == undefined || code == null) {
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID +
                '&redirect_uri=' + encodeURIComponent(local) +
                '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
        } else {

        }
    }
    public getUrlParam(name:string):any{
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        let r = (window.location.search).substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }
}